
export default {
  async asyncData ({ route, $axios, params }) {
    const result = await $axios.$get(`share-products/${params.url}`)
    return { result }
  },
  data () {
    return {
      msg: 'Please wait.....'
    }
  },
  mounted () {
    this.getData()
  },
  methods: {
    getData () {
      this.msg = this.result.text
      if (this.result.status === true) {
        if ('promotion_code' in this.result.data.params) {
          this.result.data.params.promotion_code = ''
        }
        localStorage.setItem('order' + this.result.data.params.mapping_inputs.id, JSON.stringify(this.result.data))
        this.$router.push(this.localeLocation({ name: 'customize-product-design-id', params: { design: true, id: this.result.data.params.mapping_pattern_id } }))
      }
    }
  }
}
