
export default {
  data () {
    return {
      standardProducts: null
    }
  },
  mounted () {
    this.$axios.$get('standard-products').then((res) => {
      this.standardProducts = res.data
    })
  }
}
