
import { mapMutations, mapGetters } from 'vuex'
import myDesignModal from '~/components/modal/my-designs.vue'
const cfg = require(`~/configs/${process.env.NODE_ENV}.json`)

export default {
  components: {
    myDesignModal
  },
  data () {
    return {
      drawingURL: cfg.axios.drawingURL,
      name: null,
      mappingInput: {},
      svgImage: null,
      svgUrlHighlight: null,
      imageElementHighlight: null,
      showHighlight: false,
      svgUrlHighlightFont1: null,
      imageElementHighlightFont1: null,
      showHighlightFont1: false,
      svgUrlHighlightFont2: null,
      imageElementHighlightFont2: null,
      showHighlightFont2: false,
      svgUrlHighlightFb1: null,
      imageElementHighlightFb1: null,
      showHighlightFb1: false,
      svgUrlHighlightLine1: null,
      imageElementHighlightLine1: null,
      showHighlightLine1: false,
      sizes: [],
      fonts: [],
      form: {
        size: null,
        paper: null,
        font: null,
        font1: 'โลโก้ร้าน',
        font2: 'Thank you',
        facebook: 'facebook',
        line: 'lineofficial',
        svg: '',
        semi_design_id: null
      },
      previewLoading: false,
      timer: null,
      papers: [],
      iframeHeight: '800px',
      urlDesigner: ''
    }
  },
  computed: {
    ...mapGetters(['isAuthenticated'])
  },
  async mounted() {
    const h =
    window.innerHeight ||
    document.documentElement.clientHeight ||
    document.body.clientHeight
    this.iframeHeight = h - 10
    this.showLoading()
    this.semiDesignId = this.$route.params.id
    const res = await this.$axios.$get(
      `/semi-designs/${this.semiDesignId}/templates`
    )
    this.form.semi_design_id = this.semiDesignId
    this.name = res.name
    this.sizes = res.templates.map((o) => {
      return {
        text:
          'Size ' +
          o.standard_size.code +
          ` (${o.standard_size.w} x ${o.standard_size.l} x ${o.standard_size.h})`,
        value: o.standard_size.id,
        w: o.standard_size.w,
        l: o.standard_size.l,
        h: o.standard_size.h,
        w_for_calculate: o.pattern_rel_standard_size.w_for_calculate,
        l_for_calculate: o.pattern_rel_standard_size.l_for_calculate,
        h_for_calculate: o.pattern_rel_standard_size.h_for_calculate,
        fonts: o.fonts.map((obj) => {
          return {
            text: obj.name,
            value: obj.id,
            svg: this.$store.state.aws_s3 + obj.pivot.image.path
          }
        })
      }
    })
    this.papers = res.papers.map(o => {
      return {
        text: o.name_th,
        value: o.id
      }
    })
    const semiDesignData = JSON.parse(localStorage.getItem('semi-design-data'))
    if (semiDesignData) {
      const size = this.sizes.filter(o => o.value === semiDesignData.size)[0]
      this.form.size = size.value
      this.fonts = size.fonts
      this.form.font = semiDesignData.font
      this.form.svg = semiDesignData.svg
      const paper = this.papers.filter(o => o.value === semiDesignData.paper)[0]
      this.form.paper = paper.value
      this.form.font1 = semiDesignData.font1
      this.form.font2 = semiDesignData.font2
      this.form.facebook = semiDesignData.facebook
      this.form.line = semiDesignData.line
      localStorage.removeItem('semi-design-data')
    } else {
      this.form.size = this.sizes[0].value
      this.fonts = this.sizes[0].fonts
      this.form.font = this.fonts[0].value
      this.form.svg = this.fonts[0].svg
      this.form.paper = this.papers[0].value
    }
    this.mappingInput = res.mapping_input

    await this.previewBackground()

    this.imageElementHighlight = window.document.getElementById('image-preview')
    console.log('>>___1___<<', this.form, `${this.drawingURL}/svg/highlight?font1=${this.form.font1}&font2=${this.form.font2}&fb1=${this.form.facebook}&line1=${this.form.line}&template=${this.form.svg}&display=font1,font2,fb1,line1`)
    const bufferHighlight = await this.$axios.$get(
      `${this.drawingURL}/svg/highlight?font1=${this.form.font1}&font2=${this.form.font2}&fb1=${this.form.facebook}&line1=${this.form.line}&template=${this.form.svg}&display=font1,font2,fb1,line1`,
      {
        responseType: 'blob'
      }
    )
    const bgBlobHighlight = new Blob([bufferHighlight], { type: 'image/png' })
    this.svgUrlHighlight = URL.createObjectURL(bgBlobHighlight)
    this.imageElementHighlight.addEventListener(
      'load',
      () => URL.revokeObjectURL(this.svgUrlHighlight),
      {
        once: true
      }
    )
    this.showHighlight = true

    this.hideLoading()

    this.imageElementHighlightFont1 = window.document.getElementById(
      'image-highlight-font1'
    )
    this.$axios
      .$get(
        `${this.drawingURL}/svg/highlight?font1=${this.form.font1}&font2=${this.form.font2}&fb1=${this.form.facebook}&line1=${this.form.line}&template=${this.form.svg}&display=font1`,
        {
          responseType: 'blob'
        }
      )
      .then((res) => {
        const bgBlobHighlightFont1 = new Blob([res], { type: 'image/png' })
        this.svgUrlHighlightFont1 = URL.createObjectURL(bgBlobHighlightFont1)
      })

    this.imageElementHighlightFont2 = window.document.getElementById(
      'image-highlight-font2'
    )
    this.$axios
      .$get(
        `${this.drawingURL}/svg/highlight?font1=${this.form.font1}&font2=${this.form.font2}&fb1=${this.form.facebook}&line1=${this.form.line}&template=${this.form.svg}&display=font2`,
        {
          responseType: 'blob'
        }
      )
      .then((res) => {
        const bgBlobHighlightFont2 = new Blob([res], { type: 'image/png' })
        this.svgUrlHighlightFont2 = URL.createObjectURL(bgBlobHighlightFont2)
      })

    this.imageElementHighlightFb1 = window.document.getElementById(
      'image-highlight-fb1'
    )
    this.$axios
      .$get(
        `${this.drawingURL}/svg/highlight?font1=${this.form.font1}&font2=${this.form.font2}&fb1=${this.form.facebook}&line1=${this.form.line}&template=${this.form.svg}&display=fb1`,
        {
          responseType: 'blob'
        }
      )
      .then((res) => {
        const bgBlobHighlightFb1 = new Blob([res], { type: 'image/png' })
        this.svgUrlHighlightFb1 = URL.createObjectURL(bgBlobHighlightFb1)
      })

    this.imageElementHighlightLine1 = window.document.getElementById(
      'image-highlight-line1'
    )
    this.$axios
      .$get(
        `${this.drawingURL}/svg/highlight?font1=${this.form.font1}&font2=${this.form.font2}&fb1=${this.form.facebook}&line1=${this.form.line}&template=${this.form.svg}&display=line1`,
        {
          responseType: 'blob'
        }
      )
      .then((res) => {
        const bgBlobHighlightLine1 = new Blob([res], { type: 'image/png' })
        this.svgUrlHighlightLine1 = URL.createObjectURL(bgBlobHighlightLine1)
      })
    this.showPopupLine()
    window.objCurrentPage = this
    window.closeDesignNew = function () {
      window.objCurrentPage.$bvModal.hide('modalDesigner')
      return true
    }
    window.hideLoadingFromIframe = function () {
      window.objCurrentPage.hideLoading()
      return true
    }

    window.showLoadingFromIframe = function () {
      window.objCurrentPage.showLoading()
      return true
    }
    window.closeAndSaveDesignNew = function (uuid, saveOnly) {
      if (typeof window.objCurrentPage.$refs.myDesignModal.reloadDesigns !== 'undefined' &&
        window.objCurrentPage.$refs.myDesignModal.show) {
        window.objCurrentPage.$refs.myDesignModal.reloadDesigns()
      }
      window.objCurrentPage.$bvModal.hide('modalDesigner')
      window.objCurrentPage.hideLoading()
      return true
    }
    window.closeAndSaveDesign = function (data) {
      window.objCurrentPage.$bvModal.hide('modalDesigner')
      window.objCurrentPage.setDrawingData(data)
      return true
    }
    window.clearSemiDesignEditMode = function () {
      if (window.objCurrentPage.query.semiDesignEditMode) {
        const query = Object.assign({}, window.objCurrentPage.$route.query)
        delete query.semiDesignEditMode
        window.objCurrentPage.$router.replace({ query })
        window.objCurrentPage.$cookies.remove('semiDesignEditMode')
        window.objCurrentPage.$cookies.remove('semiDesignData')
      }
    }
    window.savePngFromDesigner = function (data) {
      window.objCurrentPage.setDrawingPNG(data)
      return true
    }
    window.returnDrawingData = function () {
      return window.objCurrentPage.drawingData
    }
  },
  unmounted () {
    URL.revokeObjectURL(this.svgUrlHighlightFont1)
    URL.revokeObjectURL(this.svgUrlHighlightFont2)
    URL.revokeObjectURL(this.svgUrlHighlightFb1)
    URL.revokeObjectURL(this.svgUrlHighlightLine1)
  },
  methods: {
    ...mapMutations(['showLoading', 'hideLoading', 'setSemiDesignData']),
    ...mapActions({
      setCookie: 'setCookie',
    }),
    setDrawingPNG (data) {
      this.drawingPNG = data
    },
    showPopupLine () {
      let addLinePopup = localStorage.getItem('addLinePopup') || null
      if (addLinePopup) {
        const now = new Date()
        if (now.getTime() <= addLinePopup) {
          return false
        } else {
          const time = now.getTime() + 1800000
          localStorage.setItem('addLinePopup', time)
        }
      } else {
        const now = new Date()
        const time = now.getTime() + 1800000
        addLinePopup = [{ popup: 'true', time }]
        localStorage.setItem('addLinePopup', time)
      }
      // this.$refs['modal-add-line'].show()
      this.$store.commit('setAlertWithLine', { show: true, header: 'ออกแบบด้วยตัวเอง ได้ง่ายๆ', message: '' })
      setTimeout(() => {
        // this.$refs['modal-add-line'].hide()
        this.$store.commit('setAlertWithLine', { show: false, header: '', message: '' })
      }, 8000)
    },
    async previewBackground () {
      try {
        const url = `${this.drawingURL}/svg?font1=${this.form.font1}&font2=${this.form.font2}&fb1=${this.form.facebook}&line1=${this.form.line}&template=${this.form.svg}`
        const buffer = await this.$axios.$get(url, {
          responseType: 'blob'
        })
        const bgBlob = new Blob([buffer], { type: 'image/jpg' })
        const bgUrl = URL.createObjectURL(bgBlob)
        this.svgImage = bgUrl
        const imageObj = window.document.getElementById('image-preview')
        imageObj.addEventListener('load', () => URL.revokeObjectURL(bgUrl), {
          once: true
        })
      } catch (err) {
        console.error('error', err)
      }
    },
    async preview () {
      this.previewLoading = true
      try {
        const url = `${this.drawingURL}/svg?font1=${this.form.font1}&font2=${this.form.font2}&fb1=${this.form.facebook}&line1=${this.form.line}&template=${this.form.svg}`
        const buffer = await this.$axios.$get(url, {
          responseType: 'blob'
        })
        const bgBlob = new Blob([buffer], { type: 'image/jpg' })
        const bgUrl = URL.createObjectURL(bgBlob)
        this.svgImage = bgUrl
        const imageObj = window.document.getElementById('image-preview')
        imageObj.addEventListener('load', () => URL.revokeObjectURL(bgUrl), {
          once: true
        })
        this.previewLoading = false
        this.showHighlightFont1 = false
        this.showHighlightFont2 = false
        this.showHighlightFb1 = false
        this.showHighlightLine1 = false
      } catch (err) {
        this.previewLoading = false
        this.showHighlightFont1 = false
        this.showHighlightFont2 = false
        this.showHighlightFb1 = false
        this.showHighlightLine1 = false
      }
    },
    previewDesign (input) {
      this.previewLoading = true
      this.showHighlight = false
      if (input === 'font1') {
        this.showHighlightFont1 = true
        this.showHighlightFont2 = false
        this.showHighlightFb1 = false
        this.showHighlightLine1 = false
      }
      if (input === 'font2') {
        this.showHighlightFont1 = false
        this.showHighlightFont2 = true
        this.showHighlightFb1 = false
        this.showHighlightLine1 = false
      }
      if (input === 'fb1') {
        this.showHighlightFont1 = false
        this.showHighlightFont2 = false
        this.showHighlightFb1 = true
        this.showHighlightLine1 = false
      }
      if (input === 'line1') {
        this.showHighlightFont1 = false
        this.showHighlightFont2 = false
        this.showHighlightFb1 = false
        this.showHighlightLine1 = true
      }
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
      this.timer = setTimeout(() => {
        this.preview()
      }, 1000)
    },
    async sizeChange () {
      this.previewLoading = true
      const size = this.sizes.filter(o => o.value === this.form.size)
      this.fonts = size[0].fonts
      this.form.font = this.fonts[0].value
      this.form.svg = this.fonts[0].svg

      URL.revokeObjectURL(this.svgUrlHighlightFont1)
      URL.revokeObjectURL(this.svgUrlHighlightFont2)
      URL.revokeObjectURL(this.svgUrlHighlightFb1)
      URL.revokeObjectURL(this.svgUrlHighlightLine1)

      this.showHighlight = true
      this.showHighlightFont1 = false
      this.showHighlightFont2 = false
      this.showHighlightFb1 = false
      this.showHighlightLine1 = false

      await this.preview()

      this.previewLoading = true
      this.showHighlight = false

      this.imageElementHighlight = window.document.getElementById(
        'image-preview'
      )
      const bufferHighlight = await this.$axios.$get(
        `${this.drawingURL}/svg/highlight?font1=${this.form.font1}&font2=${this.form.font2}&fb1=${this.form.facebook}&line1=${this.form.line}&template=${this.form.svg}&display=font1,font2,fb1,line1`,
        {
          responseType: 'blob'
        }
      )
      const bgBlobHighlight = new Blob([bufferHighlight], { type: 'image/png' })
      this.svgUrlHighlight = URL.createObjectURL(bgBlobHighlight)
      this.imageElementHighlight.addEventListener(
        'load',
        () => URL.revokeObjectURL(this.svgUrlHighlight),
        {
          once: true
        }
      )
      this.showHighlight = true

      this.imageElementHighlightFont1 = window.document.getElementById(
        'image-highlight-font1'
      )
      this.$axios
        .$get(
          `${this.drawingURL}/svg/highlight?font1=${this.form.font1}&font2=${this.form.font2}&fb1=${this.form.facebook}&line1=${this.form.line}&template=${this.form.svg}&display=font1`,
          {
            responseType: 'blob'
          }
        )
        .then((res) => {
          const bgBlobHighlightFont1 = new Blob([res], { type: 'image/png' })
          this.svgUrlHighlightFont1 = URL.createObjectURL(bgBlobHighlightFont1)
        })

      this.imageElementHighlightFont2 = window.document.getElementById(
        'image-highlight-font2'
      )
      this.$axios
        .$get(
          `${this.drawingURL}/svg/highlight?font1=${this.form.font1}&font2=${this.form.font2}&fb1=${this.form.facebook}&line1=${this.form.line}&template=${this.form.svg}&display=font2`,
          {
            responseType: 'blob'
          }
        )
        .then((res) => {
          const bgBlobHighlightFont2 = new Blob([res], { type: 'image/png' })
          this.svgUrlHighlightFont2 = URL.createObjectURL(bgBlobHighlightFont2)
        })

      this.imageElementHighlightFb1 = window.document.getElementById(
        'image-highlight-fb1'
      )
      this.$axios
        .$get(
          `${this.drawingURL}/svg/highlight?font1=${this.form.font1}&font2=${this.form.font2}&fb1=${this.form.facebook}&line1=${this.form.line}&template=${this.form.svg}&display=fb1`,
          {
            responseType: 'blob'
          }
        )
        .then((res) => {
          const bgBlobHighlightFb1 = new Blob([res], { type: 'image/png' })
          this.svgUrlHighlightFb1 = URL.createObjectURL(bgBlobHighlightFb1)
        })

      this.imageElementHighlightLine1 = window.document.getElementById(
        'image-highlight-line1'
      )
      this.$axios
        .$get(
          `${this.drawingURL}/svg/highlight?font1=${this.form.font1}&font2=${this.form.font2}&fb1=${this.form.facebook}&line1=${this.form.line}&template=${this.form.svg}&display=line1`,
          {
            responseType: 'blob'
          }
        )
        .then((res) => {
          const bgBlobHighlightLine1 = new Blob([res], { type: 'image/png' })
          this.svgUrlHighlightLine1 = URL.createObjectURL(bgBlobHighlightLine1)
        })
      this.previewLoading = false
    },
    async fontChange () {
      const font = this.fonts.filter(o => o.value === this.form.font)
      this.form.svg = font[0].svg

      URL.revokeObjectURL(this.svgUrlHighlightFont1)
      URL.revokeObjectURL(this.svgUrlHighlightFont2)
      URL.revokeObjectURL(this.svgUrlHighlightFb1)
      URL.revokeObjectURL(this.svgUrlHighlightLine1)

      this.showHighlight = true
      this.showHighlightFont1 = false
      this.showHighlightFont2 = false
      this.showHighlightFb1 = false

      await this.preview()

      this.previewLoading = true
      this.showHighlight = false

      this.imageElementHighlight = window.document.getElementById(
        'image-preview'
      )
      const bufferHighlight = await this.$axios.$get(
        `${this.drawingURL}/svg/highlight?font1=${this.form.font1}&font2=${this.form.font2}&fb1=${this.form.facebook}&line1=${this.form.line}&template=${this.form.svg}&display=font1,font2,fb1,line1`,
        {
          responseType: 'blob'
        }
      )
      const bgBlobHighlight = new Blob([bufferHighlight], { type: 'image/png' })
      this.svgUrlHighlight = URL.createObjectURL(bgBlobHighlight)
      this.imageElementHighlight.addEventListener(
        'load',
        () => URL.revokeObjectURL(this.svgUrlHighlight),
        {
          once: true
        }
      )
      this.showHighlight = true

      this.imageElementHighlightFont1 = window.document.getElementById(
        'image-highlight-font1'
      )
      this.$axios
        .$get(
          `${this.drawingURL}/svg/highlight?font1=${this.form.font1}&font2=${this.form.font2}&fb1=${this.form.facebook}&line1=${this.form.line}&template=${this.form.svg}&display=font1`,
          {
            responseType: 'blob'
          }
        )
        .then((res) => {
          const bgBlobHighlightFont1 = new Blob([res], { type: 'image/png' })
          this.svgUrlHighlightFont1 = URL.createObjectURL(bgBlobHighlightFont1)
        })

      this.imageElementHighlightFont2 = window.document.getElementById(
        'image-highlight-font2'
      )
      this.$axios
        .$get(
          `${this.drawingURL}/svg/highlight?font1=${this.form.font1}&font2=${this.form.font2}&fb1=${this.form.facebook}&line1=${this.form.line}&template=${this.form.svg}&display=font2`,
          {
            responseType: 'blob'
          }
        )
        .then((res) => {
          const bgBlobHighlightFont2 = new Blob([res], { type: 'image/png' })
          this.svgUrlHighlightFont2 = URL.createObjectURL(bgBlobHighlightFont2)
        })

      this.imageElementHighlightFb1 = window.document.getElementById(
        'image-highlight-fb1'
      )
      this.$axios
        .$get(
          `${this.drawingURL}/svg/highlight?font1=${this.form.font1}&font2=${this.form.font2}&fb1=${this.form.facebook}&line1=${this.form.line}&template=${this.form.svg}&display=fb1`,
          {
            responseType: 'blob'
          }
        )
        .then((res) => {
          const bgBlobHighlightFb1 = new Blob([res], { type: 'image/png' })
          this.svgUrlHighlightFb1 = URL.createObjectURL(bgBlobHighlightFb1)
        })

      this.imageElementHighlightLine1 = window.document.getElementById(
        'image-highlight-line1'
      )
      this.$axios
        .$get(
          `${this.drawingURL}/svg/highlight?font1=${this.form.font1}&font2=${this.form.font2}&fb1=${this.form.facebook}&line1=${this.form.line}&template=${this.form.svg}&display=line1`,
          {
            responseType: 'blob'
          }
        )
        .then((res) => {
          const bgBlobHighlightLine1 = new Blob([res], { type: 'image/png' })
          this.svgUrlHighlightLine1 = URL.createObjectURL(bgBlobHighlightLine1)
        })
      this.previewLoading = false
    },
    order () {
      this.gotoLocopackDesign('no')
    },
    design () {
      this.gotoLocopackDesign('yes')
    },
    async saveSemiDesign() {
      if (!this.isAuthenticated) {
        localStorage.setItem(
          'click-login-from-page',
          'semi-design-id'
        )
        localStorage.setItem(
          'click-login-from-page-params',
          JSON.stringify({ id: this.semiDesignId })
        )
        localStorage.setItem(
          'semi-design-data',
          JSON.stringify(this.form)
        )
        this.$router.push(this.localeLocation({ name: 'auth-login-via-phone' }))
      } else {
        this.showLoading()
        const size = this.sizes.filter(o => o.value === this.form.size)[0]
        let flute = await this.$getFlute(this.form.paper)
        let w_for_calculate = size.w_for_calculate && size.w_for_calculate > 0 ? size.w_for_calculate : size.w
        let l_for_calculate = size.l_for_calculate && size.l_for_calculate > 0 ? size.l_for_calculate : size.l
        let h_for_calculate = size.h_for_calculate && size.h_for_calculate > 0 ? size.h_for_calculate : size.h
        await this.$axios.$post('/designs/create-designs', {
          mapping_input_id: this.mappingInput.id,
          w: size.w * 10,
          l: size.l * 10,
          h: size.h * 10,
          w_for_calculate: w_for_calculate * 10,
          l_for_calculate: l_for_calculate * 10,
          h_for_calculate: h_for_calculate * 10,
          flute: flute,
          select_size_type: 'normal',
          design_type: 'semi-design',
          semi_design_id: this.form.semi_design_id,
          semi_design_url: `/svg/design?font1=${this.form.font1}&font2=${this.form.font2}&fb1=${this.form.facebook}&line1=${this.form.line}&template=${this.form.svg}`,
          paper_id: this.form.paper
        })
        this.$router.push(this.localeLocation({ name: 'my-designs' }))
      }
    },
    async editSemiDesign() {
      if (!this.isAuthenticated) {
        localStorage.setItem(
          'click-login-from-page',
          'semi-design-id'
        )
        localStorage.setItem(
          'click-login-from-page-params',
          JSON.stringify({ id: this.semiDesignId })
        )
        localStorage.setItem(
          'semi-design-data',
          JSON.stringify(this.form)
        )
        this.$router.push(this.localeLocation({ name: 'auth-login-via-phone' }))
      } else {
        this.showLoading()
        const size = this.sizes.filter(o => o.value === this.form.size)[0]
        let flute = await this.$getFlute(this.form.paper)
        let w_for_calculate = size.w_for_calculate && size.w_for_calculate > 0 ? size.w_for_calculate : size.w
        let l_for_calculate = size.l_for_calculate && size.l_for_calculate > 0 ? size.l_for_calculate : size.l
        let h_for_calculate = size.h_for_calculate && size.h_for_calculate > 0 ? size.h_for_calculate : size.h
        const userDesign = await this.$axios.$post('/designs/create-designs', {
          mapping_input_id: this.mappingInput.id,
          w: size.w * 10,
          l: size.l * 10,
          h: size.h * 10,
          w_for_calculate: w_for_calculate * 10,
          l_for_calculate: l_for_calculate * 10,
          h_for_calculate: h_for_calculate * 10,
          flute: flute,
          select_size_type: 'normal',
          design_type: 'semi-design',
          semi_design_id: this.form.semi_design_id,
          semi_design_url: `/svg/design?font1=${this.form.font1}&font2=${this.form.font2}&fb1=${this.form.facebook}&line1=${this.form.line}&template=${this.form.svg}`,
          paper_id: this.form.paper
        })
        this.$router.push(this.localeLocation({ name: 'my-designs', query: { open: userDesign.data.uuid } }))
      }
    },
    async checkPrice() {
      if (!this.isAuthenticated) {
        localStorage.setItem(
          'click-login-from-page',
          'semi-design-id'
        )
        localStorage.setItem(
          'click-login-from-page-params',
          JSON.stringify({ id: this.semiDesignId })
        )
        localStorage.setItem(
          'semi-design-data',
          JSON.stringify(this.form)
        )
        this.$router.push(this.localeLocation({ name: 'auth-login-via-phone' }))
      } else {
        this.showLoading()
        const size = this.sizes.filter(o => o.value === this.form.size)[0]
        let flute = await this.$getFlute(this.form.paper)
        let w_for_calculate = size.w_for_calculate && size.w_for_calculate > 0 ? size.w_for_calculate : size.w
        let l_for_calculate = size.l_for_calculate && size.l_for_calculate > 0 ? size.l_for_calculate : size.l
        let h_for_calculate = size.h_for_calculate && size.h_for_calculate > 0 ? size.h_for_calculate : size.h
        const userDesign = await this.$axios.$post('/designs/create-designs', {
          mapping_input_id: this.mappingInput.id,
          w: size.w * 10,
          l: size.l * 10,
          h: size.h * 10,
          w_for_calculate: w_for_calculate * 10,
          l_for_calculate: l_for_calculate * 10,
          h_for_calculate: h_for_calculate * 10,
          flute: flute,
          select_size_type: 'normal',
          design_type: 'semi-design',
          semi_design_id: this.form.semi_design_id,
          semi_design_url: `/svg/design?font1=${this.form.font1}&font2=${this.form.font2}&fb1=${this.form.facebook}&line1=${this.form.line}&template=${this.form.svg}`,
          paper_id: this.form.paper
        })
        this.$router.push(this.localePath({ name: 'customize-product-design-id', params: { design: true, id: this.mappingInput.id }, query: { hasDesign: true, uuid: userDesign.data.uuid } }))
      }
    },
    async openModalMyDesigns () {
      if (this.isAuthenticated) {
        // const myDesignParams = { pattern_id: this.patternSelected, w: this.w, l: this.l, h: this.h }
        // if (this.packagingTypeId === 3) {
        //   myDesignParams.paper_grade_id = this.cardPaperActive
        // } else {
        //   myDesignParams.paper_id = this.cardPaperActive
        // }
        const size = this.sizes.filter(o => o.value === this.form.size)[0]
        const paper = this.papers.filter(o => o.value === this.form.paper)[0]
        let flute = await this.$getFlute(this.form.paper)
        let w_for_calculate = size.w_for_calculate && size.w_for_calculate > 0 ? size.w_for_calculate : size.w
        let l_for_calculate = size.l_for_calculate && size.l_for_calculate > 0 ? size.l_for_calculate : size.l
        let h_for_calculate = size.h_for_calculate && size.h_for_calculate > 0 ? size.h_for_calculate : size.h
        await this.$refs.myDesignModal.getMyDesigns({
          mapping_input_id: this.mappingInput.id,
          w: size.w * 10,
          l: size.l * 10,
          h: size.h * 10,
          w_for_calculate: w_for_calculate * 10,
          l_for_calculate: l_for_calculate * 10,
          h_for_calculate: h_for_calculate * 10,
          flute: flute,
          select_size_type: 'normal',
          design_type: 'semi-design',
          semi_design_id: this.form.semi_design_id,
          semi_design_url: `/svg/design?font1=${this.form.font1}&font2=${this.form.font2}&fb1=${this.form.facebook}&line1=${this.form.line}&template=${this.form.svg}`,
          paper_id: this.form.paper
        })
        // this.$refs.myDesignModal.getMyDesigns(myDesignParams)
        this.hideLoading()
        this.$refs.myDesignModal.show = true
        const paperName = paper.text
        this.$refs.myDesignModal.subHeader = `${this.mappingInput.name_th} ${size.w} x ${size.l} x ${size.h} ซม. ${paperName}`
      }
    },
    openMyDesignModal (design) {
      this.showLoading()
      // this.urlDesigner = `http://localhost:3003/designer?flow=edit&uuid=${design.uuid}`
      this.urlDesigner = `/designer?flow=edit&uuid=${design.uuid}`
      this.$bvModal.show('modalDesigner')
    },
    orderDesign (design) {
      this.$router.push(this.localePath({ name: 'customize-product-design-id', params: { design: true, id: design.mapping_input_id }, query: { hasDesign: true, uuid: design.uuid } }))
    },
    gotoLocopackDesign (semiDesignEditMode) {
      // this.setSemiDesignData(this.form)
      this.setCookie({ cookieName: 'semiDesignData', cookieValue: this.form })
      if (!this.isAuthenticated) {
        localStorage.setItem(
          'click-login-from-page',
          'customize-product-design-id'
        )
        localStorage.setItem(
          'click-login-from-page-params',
          JSON.stringify({ design: true, id: this.mappingInput.id })
        )
        localStorage.setItem(
          'click-login-from-page-query',
          JSON.stringify({ standardSizeId: this.form.size, semiDesignEditMode })
        )
        this.$router.push(this.localeLocation({ name: 'auth-login-via-phone' }))
      } else {
        this.showLoading()
        this.$router.push(this.localeLocation({
          name: 'customize-product-design-id',
          params: { design: true, id: this.mappingInput.id },
          query: { standardSizeId: this.form.size, semiDesignEditMode }
        }))
      }
    }
  }
}
