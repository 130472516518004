
import { mapMutations } from 'vuex'
export default {
  data () {
    return {
    }
  },
  async mounted () {
    this.showLoading()
    await this.$firebaseBannerPlugin()
    // window.location.href = 'https://line.me/R/ti/p/@gwc6379j?oat__id=1303339'
    window.location.href = 'https://bit.ly/3ZxXeFz'
    await this.hideLoading()
  },
  methods: {
    ...mapMutations(['showLoading', 'hideLoading'])
  }
}
