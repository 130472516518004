
import { mapMutations, mapState } from 'vuex'
import InfiniteLoading from 'vue-infinite-loading'
export default {
  components: {
    InfiniteLoading
  },
  async asyncData ({ route, $axios, $replaceSEO }) {
    let seo
    if (route.query.sub) {
      seo = await $axios.get('sitemaps/products_category_sub')
    } else if (route.query.category) {
      seo = await $axios.get('sitemaps/products_category')
    } else {
      seo = await $axios.get('sitemaps/products')
    }
    // let res = []

    await $axios.$get('/search', {
      params: {
        keyword: route.query.keyword
      }
    })
    // const packaingTypes = await $axios.$get('packaging-types')
    return {
      // packaing_types: packaingTypes.data,
      keyword: route.query.keyword,
      /* products: res.products,
      sampleProducts: res.sampleProducts,
      articles: res.articles, */
      seo: $replaceSEO(seo.data.data, null, route.query.category, route.query.sub)
    }
  },
  data () {
    return {
      selectedCatID: 0,
      slide: 0,
      sliding: null,
      sampleProductCategories: [],
      sampleProducts: [],
      loading: false,
      modalProductDetial: {
        name: '',
        description: '',
        imageSlide: [],
        relatedProducts: []
      },
      waypointLoaded: [],
      maxImpression: 6,
      products: [],
      productLength: 0,
      page: 1
    }
  },
  computed: {
    ...mapState(['userData'])
  },
  mounted () {
    this.waypointLoaded = []
    // console.log(this.products)
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({ ecommerce: null })
    window.dataLayer.push({
      event: 'pageInfo',
      page: {
        type: 'searchresults',
        path: `${this.$route.path}`,
        title: this.seo.title
      },
      user: {
        customerID: (this.userData) ? this.userData.uid : '',
        customerEmail: (this.userData) ? this.userData.email : ''
      }
    })
  },
  methods: {
    ...mapMutations(['showLoading', 'hideLoading', 'setAlert']),
    selectChange () {
      // this.packagingTypeId = this.selectData.item.packaging_type_id

    },
    viewArticle (itemId) {
      return { name: 'articles-id', params: { id: itemId } }
    },
    productClicked (pattern, indexPT) {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({ ecommerce: null })
      window.dataLayer.push({
        event: 'productClicked',
        ecommerce: {
          click: {
            actionField: { list: 'ค้นหา-' + this.keyword },
            products: [{
              id: `LOCOPACK_${pattern.packaging_type_id}` + pattern.id.toString().padStart(9, '0'),
              name: pattern.name,
              price: pattern.per_pcs,
              // category: this.packaing_types[pattern.packaging_type_id - 1].packaging_type_name,
              category: '',
              brand: 'Locopack',
              position: 1,
              dimension1: '',
              dimension2: '',
              dimension3: '',
              dimension4: '',
              dimension5: '',
              dimension6: '',
              dimension7: '',
              dimension8: '',
              dimension9: '',
              dimension10: '',
              dimension11: '',
              dimension12: '',
              dimension13: ''
            }]
          }
        }
      })
    },
    customToFixed (number) {
      number = parseFloat(number)
      if (!isNaN(number) && number !== '' && number !== null && number !== undefined) {
        number = parseFloat(number)
        return number.toFixed(2)
      } else {
        return 0.00
      }
    },
    onWaypoint ({ going, direction, el }) {
      // console.log('waypoint going in!', going === this.$waypointMap.GOING_IN, typeof direction === 'undefined', el.getAttribute('data-pattern-index'))
      const inIdx = parseInt(el.getAttribute('data-pattern-index'))
      if ((going === this.$waypointMap.GOING_IN && direction === this.$waypointMap.DIRECTION_TOP) ||
      (going === this.$waypointMap.GOING_IN && typeof direction === 'undefined' && inIdx === 0)) {
        const impressions = []
        let pos = 0
        for (let k = inIdx; pos < this.maxImpression; k++) {
          const item = this.products[k]
          if (k in this.products && typeof this.waypointLoaded[item.id] === 'undefined' && this.waypointLoaded[item.id] !== true) {
            impressions.push({
              id: `LOCOPACK_${item.packaging_type_id}` + item.id.toString().padStart(9, '0'),
              name: item.name,
              // price: ((item.per_pcs !== null) ? item.per_pcs.toFixed(2) : 0), // fix bug
              price: this.customToFixed(item.per_pcs),
              category: this.packaing_types[item.packaging_type_id - 1].packaging_type_name,
              brand: 'Locopack',
              list: 'ค้นหา-' + this.keyword,
              position: (k + 1),
              dimension1: '',
              dimension2: '',
              dimension3: '',
              dimension4: '',
              dimension5: '',
              dimension6: '',
              dimension7: '',
              dimension8: '',
              dimension9: '',
              dimension10: '',
              dimension11: '',
              dimension12: '',
              dimension13: ''
            })
            this.waypointLoaded[item.id] = true
          }
          pos++
        }
        // console.log(impressions)
        if (impressions.length > 0) {
          window.dataLayer = window.dataLayer || []
          window.dataLayer.push({ ecommerce: null })
          window.dataLayer.push({
            event: 'impression',
            ecommerce: {
              impressions
            }
          })
        }
      }
    },
    async getFilter () {
      this.showLoading()
      const newFilterData = {}
      for (const x in this.filterData) {
        newFilterData[x] = []
        this.filterData[x].data.forEach((item) => {
          if (item.check === true) {
            if (x === 'sizes') {
              newFilterData[x].push(`${item.min}-${item.max}`)
            } else {
              newFilterData[x].push(item.id)
            }
          }
        })
        newFilterData[x] = newFilterData[x].join()
      }
      newFilterData.packaging_type_id = this.packagingTypeId
      newFilterData.sub_types = this.subTypeId
      newFilterData.without_category = 1
      const products = await this.$axios.$get('/products', { params: newFilterData })

      this.productsData.products = products.products
      this.hideLoading()
    },
    change_category () {
      const query = {}
      if (this.selectData.item) {
        query.category = this.selectData.item
      }
      if (this.selectData.sub) {
        query.sub = this.selectData.sub
      }
      this.$router.push(this.localeLocation({ query }))
      setTimeout(() => {
        this.$router.go(this.localeLocation({ path: '/', force: true, query }))
      }, 500)
    },
    async setModalDetail (productDetail) {
      await this.$axios.get(`sample-product-tracking/${productDetail.id}`)
      this.modalProductDetial = {
        name: productDetail.name,
        description: productDetail.description,
        imageSlide: productDetail.img,
        relatedProducts: productDetail.products
      }
    },
    linkToProduct (pkgType, mappingInputId, productId) {
      if (pkgType !== 4) {
        return { name: 'customize-product-design-id', params: { design: true, id: mappingInputId }, query: { productId } }
      } else {
        return { name: 'products-detail-id', params: { id: mappingInputId }, query: { productId } }
      }
    },
    onSlideStart (slide) {
      this.sliding = true
    },
    onSlideEnd (slide) {
      this.sliding = false
    },
    async loadProducts ($state) {
      try {
        await this.$axios.get('v2/products', {
          params: {
            page: this.page,
            keyword: this.keyword
          }
        }).then((res) => {
          const data = res.data
          this.productLength = data.total
          if (data.data.length) {
            this.products.push(...data.data)
            if (this.page >= data.last_page) {
              $state.complete()
            } else {
              $state.loaded()
            }
            this.page++
          } else {
            $state.complete()
          }
        })
      } catch (error) {
        console.error('Error loading items:', error)
        $state.error()
      }
    }
  },
  head () {
    return {
      title: this.seo.title,
      meta: [
        { hid: 'description', name: 'description', content: this.seo.description },
        { hid: 'keywords', name: 'keywords', content: this.seo.keywords }
      ],
      link: [
        {
          rel: 'canonical',
          href: this.seo.canonical_url
        }
      ]
    }
  }

}
