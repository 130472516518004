
import { mapMutations } from 'vuex'
export default {
  asyncData ({ params, query }) {
    let userId = 0
    if (query.id) {
      userId = parseInt(query.id)
    }
    return {
      userId
    }
  },
  validate ({ params, query }) {
    if (query.id) {
      return parseInt(query.id) > 0
    } else {
      return true
    }
  },
  data () {
    return {
      acceptCheckbox: false
    }
  },
  mounted () {
    // console.log(this.$route.fullPath)
  },
  methods: {
    ...mapMutations(['showLoading', 'hideLoading', 'setAlert']),
    async acceptTerm () {
      this.showLoading()
      const result = await this.$axios.$post('users/accept-term',
        {
          userId: this.userId
        }
      )
      if (result.status === true) {
        this.$router.push(this.localeLocation('/'))
      } else {
        alert('Please reload and try again.')
        this.hideLoading()
      }
    }
  }
}
