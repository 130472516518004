
export default {
  components: {},
  async asyncData ({ route, $axios, app, $replaceSEO, params }) {
    const data = await $axios.get('articles', {
      params: { category_name: params.tag }
    })
    const articles = data.data.data
    const category = data.data.category

    return {
      articles,
      category
    }
  },
  data () {
    return {
      headTitle: 'บทความบรรจุภัณฑ์ การสร้างแบรนด์ การออกแบบ เข้าใจง่ายมีประโยชน์',
      headDes: 'บทความบรรจุภัณฑ์ การออกแบบ สร้างแบรนด์ และผลิตกล่องกระดาษ กล่องครีม กล่องอาหารเสริม กล่องไปรษณีย์ การขายออนไลน์ การส่งพัสดุ ส่งเดลิเวอรี่ เข้าใจง่ายมีประโยชน์',
      headKey: 'ผลิตกล่อง,กล่องกระดาษ,กล่องไปรษณีย์,กล่องลูกฟูก,กล่องสวยๆ,กล่องครีม,สติกเกอร์,สติกเกอร์ติดกล่องอาหาร,ออกแบบบรรจุภัณฑ์,โรงงานผลิตกล่อง,บรรจุภัณฑ์,กล่องส่งพัสดุ,กล่องอาหาร,หลอดครีม,ขวดพลาสติก,ถุงพลาสติก,พลาสติก,กล่องสวยๆ,ส่งเดลิเวอรี่,การส่งพัสดุ,การขายออนไลน์',
      ogTitle: '',
      ogDes: ''
    }
  },
  mounted () {
  },
  methods: {
    viewArticle (itemId) {
      return { name: 'articles-id', params: { id: itemId } }
    }
  },
  head () {
    return {
      title: this.category.seo_title,
      meta: [
        { hid: 'description', name: 'description', content: this.category.seo_description },
        { hid: 'keywords', name: 'keywords', content: this.category.seo_keyword }
      ],
      link: [
        {
          rel: 'canonical',
          href: ''
        }
      ]
    }
  }
}
