
export default {
  data () {
    return {
      semiDesigns: null
    }
  },
  mounted () {
    this.$axios.$get('semi-designs').then((res) => {
      this.semiDesigns = res.data
    })
    this.showPopupLine()
  },
  methods: {
    showPopupLine () {
      let addLinePopupSemiDesign = localStorage.getItem('addLinePopupSemiDesign') || null
      if (addLinePopupSemiDesign) {
        const now = new Date()
        if (now.getTime() <= addLinePopupSemiDesign) {
          return false
        } else {
          const time = now.getTime() + 1800000
          localStorage.setItem('addLinePopupSemiDesign', time)
        }
      } else {
        const now = new Date()
        const time = now.getTime() + 1800000
        addLinePopupSemiDesign = [{ popup: 'true', time }]
        localStorage.setItem('addLinePopupSemiDesign', time)
      }
      // this.$refs['modal-add-line'].show()
      this.$store.commit('setAlertWithLine', { show: true, header: 'ออกแบบด้วยตัวเอง ได้ง่ายๆ', message: '' })
      setTimeout(() => {
        this.$store.commit('setAlertWithLine', { show: false, header: '', message: '' })
      }, 8000)
    },
    getData () {},
    slideLeft (el, width) {
      const content = document.querySelector(el)
      const translate = 0
      content.scrollLeft -= width
      content.style.transform = 'translateX(' + translate + 'px' + ')'
    },
    slideRight (el, width) {
      const content = document.querySelector(el)
      const translate = 0
      content.scrollLeft += width
      content.style.transform = 'translateX(' + translate + 'px' + ')'
    }
  }
}
